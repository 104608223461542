import React, { useEffect } from "react";
import * as firebaseui from 'firebaseui'
import 'firebaseui/dist/firebaseui.css'
import firebase from 'firebase/compat/app'
import '../App.css';
import { initializeApp } from "firebase/app";
import { getAuth, signInWithPopup, GoogleAuthProvider } from "firebase/auth";

const firebaseConfig = {
    apiKey: "AIzaSyBiGxtqO2osGjNNB7-A2Dw6_kdYTGUeUXU",
    authDomain: "weaposy.firebaseapp.com",
    databaseURL: "https://weaposy-default-rtdb.asia-southeast1.firebasedatabase.app",
    projectId: "weaposy",
    storageBucket: "weaposy.appspot.com",
    messagingSenderId: "780936679265",
    appId: "1:780936679265:web:3c5243abd9fa10ddc53f20",
    measurementId: "G-3NZ1WT3XQK"
};
// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);
// Initialize Firebase Auth provider
const provider = new GoogleAuthProvider();

// whenever a user interacts with the provider, we force them to select an account
provider.setCustomParameters({
    prompt: "select_account "
});
export const auth = getAuth();
export const signInWithGooglePopup = () => signInWithPopup(auth, provider);

/*const AuthGoogle = (props) => {
    useEffect(()=>{
        
        const ui = firebaseui.auth.AuthUI.getInstance() || new firebaseui.auth.AuthUI(props.auth)
        ui.start('.firebase-auth-container', {
            signInOptions: [
                {
                    provider: firebase.auth.GoogleAuthProvider.PROVIDER_ID,
                    //provider: firebase.auth.EmailAuthProvider.PROVIDER_ID,
                    requireDisplayName: false,
                    disableSignUp: { status: true, adminEmail: "weaponstory@gmail.com", helpLink: "" },
                    customParameters: {
                      // Forces account selection even when one account
                      // is available.
                      prompt: 'select_account'
                    }
                }
            ],
            signInSuccessUrl: '',//'/authenticated',
            privacyPolicyUrl: 'https://sites.google.com/view/weaponstory-privacy-policy/%E0%B8%AB%E0%B8%99%E0%B8%B2%E0%B9%81%E0%B8%A3%E0%B8%81'
        });
    }, [props.auth])
    return (
        <div>
            <div className={'firebase-auth-container'}></div>
        </div>
    )
}

export default AuthGoogle*/