import logo from './Logo.png';
import React, { useEffect, useState } from 'react'
import { BrowserRouter, Routes, Route, Navigate, useResolvedPath } from 'react-router-dom'
import './App.css';
import firebase from 'firebase/compat/app'
//import AuthGoogle from './components/AuthGoogle';
import Authenticated from './components/Authenticated';
import { auth, signInWithGooglePopup } from "./components/AuthGoogle"

function App() {
  const firebaseConfig = {
    apiKey: "AIzaSyBiGxtqO2osGjNNB7-A2Dw6_kdYTGUeUXU",
    authDomain: "weaposy.firebaseapp.com",
    databaseURL: "https://weaposy-default-rtdb.asia-southeast1.firebasedatabase.app",
    projectId: "weaposy",
    storageBucket: "weaposy.appspot.com",
    messagingSenderId: "780936679265",
    appId: "1:780936679265:web:3c5243abd9fa10ddc53f20",
    measurementId: "G-3NZ1WT3XQK"
  };

  const logGoogleUser = async () => {
    const response = await signInWithGooglePopup();
    console.log(response);
  }

  const [user, setUser] = useState(null)
  const [accounts, setAccounts] = useState(null)
  const [ready, setReady] = useState(false)
  const [alreadyDoneProcess, setAlreadyDoneProcess] = useState("")
  const [loadlogin, setLoadlogin] = useState(false)

  // Initialize Firebase
  const fb = firebase.initializeApp(firebaseConfig);

  const doneProcess = (type) => {
    setAlreadyDoneProcess(type)
  }

  firebase.auth().onAuthStateChanged(user => {
    if (user) {
      setUser({ uid: user.uid })
    }
    else {
      setUser(null);
    }
  });

  useEffect(() => {
    if (user != null) {
      if (!loadlogin) {
        setLoadlogin(true);
        loadAccounts(user.uid);
      }
    }
    else {
      if (loadlogin) {
        setLoadlogin(false);
        setAccounts(null)
        setReady(false)
      }
    }
  }, [user])

  const loadAccounts = (userid) => {
    var formData = new FormData();
    var dCommand = ["", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", ""];
    var sCommand = ["", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", ""];
    var rVariable = ["email", "charname", "tier", "gold", "cash", "fire", "water", "wind", "earth", "cardslot", "penalty", "leavebattle", "", "", "", "", "", "", "", ""];
    var lCommand = "SELECT * FROM characters WHERE userid = '" + userid + "'";
    for (var i = 0; i < 20; i++) {
      formData.append('sqldeletecommand' + i, dCommand[i]);
      formData.append('sqlsavecommand' + i, sCommand[i]);
      formData.append('rt' + i, rVariable[i]);
    }
    formData.append('sqlloadcommand', lCommand);
    fetch('https://www.weapon-story.com/weaposy/SQL_Controller.php', {
      method: 'POST',
      mode: 'cors',
      body: formData
    })
      .then((res) => {
        return res.text();
      }).then((data) => {
        //console.log(data);
        var eachData = data.split("_____");
        if (eachData != "") {
          setAccounts({
            email: eachData[0], charname: eachData[1], tier: eachData[2], gold: eachData[3], cash: eachData[4], fire: eachData[5], water: eachData[6],
            wind: eachData[7], earth: eachData[8], cardslot: eachData[9], penalty: eachData[10], leavebattle: eachData[11]
          });
        }
        else {
          setAccounts(null);
        }
        setReady(true);
      }).catch((error) => {
        //console.log('mute false error');
      })
  }

  return (
    <div className="App">
      <div className="title-header">
        <img src={logo} className='logo' alt="logo" />
        {/*Weaposy*/}
      </div>
      {user != null ? (
        <div>
          <Authenticated user={user} ready={ready} doneProcess={doneProcess} accounts={accounts} />
        </div>
      ) : (
        <div>
          <button onClick={logGoogleUser}>Sign In With Google</button>
          {/*<AuthGoogle auth={firebase.auth()}/>*/}
          {alreadyDoneProcess === "Delete" ? <div className="alert-text">Account is deleted</div> : ""}
        </div>
      )}
    </div>
  );
}

export default App;